@import "../../node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@import "colors";

.lbc-contact-icon {
  @extend .icon-link;
  @extend .d-block;
  @extend .p-2;
  font-size: 2em;
}

.table-training-plan {
  width: 75%;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  border-radius: 15px;
  
  background-color: $platinum;
}

.chip-free {
  @extend .chip;
  background-color: $old-rose;
}

.chip-bambi {
  @extend .chip;
  background-color: $desert-sand;
  color: $night;
}

.chip-kids {
  @extend .chip;
  background-color: $cocoa-brown;
}

.chip-boxing {
  @extend .chip;

  background-color: $red;
}

.chip-muay-thai {
  @extend .chip;
  background-color: $cornell-red;
}

.chip-kickboxing {
  @extend .chip;
  background-color: $caput-mortuum;
}

.chip-sparring {
  @extend .chip;
  background-color: $black-bean;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $dark;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $red;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey;
}